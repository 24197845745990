* {
  margin: 0;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
::-ms-overflow {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



/* colors */
:root {
  /* Primary colors */
  --black: #000;
  --white: #fff;
  --red: #ff0000;

}

/* Segoe UI*/

@font-face {
  font-family: 'Segoe UI';
  src: url('assets/fonts/Segoe\ UI.ttf');
}

@font-face {
  font-family: 'Segoe UI-blod';
  src: url('assets/fonts/Segoe\ UI\ Bold.ttf');
}

/* Manrope fonts */
@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-VariableFont_wght.ttf');
}