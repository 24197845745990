.cardContainerStyles {
    max-width: 386px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.titleTextStyles {
    max-width: 354px;
    width: 100%;
    font-family: "Segoe UI", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 63.84px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000;
}

.descriptionTextStyles {
    max-width: 354px;
    width: 100%;
    font-family: "Segoe UI", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000CC;
}