.footerContainerStyles {
  max-width: 1640px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 48px 24px 64px 24px;
  background-color: #070d14;
  justify-content: center;
  align-items: center;
}

.linkedInTextStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 96px;
  font-weight: 400;
  line-height: 127.69px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
}

.contactBlockStyles {
  max-width: 688px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.contactContentViewStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #808080;
}

.contactLeftTitleStyles,
.contactRightTitleStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.94px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.contactRightContentStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
}

.contactImageStyles {
  width: 24px;
  height: 24px;
}

.bottomAddressBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.bottomAddressSubBlockStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
}

.topAddressDataViewStyles {
  width: 214px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.addressTitleStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffff;
}

.addressDescStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #ffffff80;
}

.bottomAddressSubBlockStyles
  .topAddressDataViewStyles:nth-child(2)
  .addressDescStyles {
  max-width: 100px;
}

.bottomCopyRightTextStyles {
  font-family: 'Manrope', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #f4f4f480;
  white-space: nowrap;
}

/* Media Query Start */
@media screen and (max-width: 1440px) {
}

@media screen and (max-width: 1287px) {
  .contactLeftTitleStyles,
  .contactRightTitleStyles {
    font-size: 16px;
    line-height: 21.28px;
  }

  .addressTitleStyles {
    font-size: 16px;
  }

  .addressDescStyles {
    font-size: 16px;
  }

  .bottomCopyRightTextStyles {
    font-size: 16px;
  }
}

@media screen and (max-width: 1040px) {
  .contactBlockStyles {
    max-width: 100%;
  }
}

@media screen and (max-width: 740px) {
  .bottomAddressSubBlockStyles {
    justify-content: space-between;
  }

  .topAddressDataViewStyles {
    width: 100%;
  }

  .bottomAddressSubBlockStyles
    .topAddressDataViewStyles:nth-child(2)
    .addressDescStyles {
    width: 100%;
    max-width: 87px;
  }
}

@media screen and (max-width: 560px) {
  .linkedInTextStyles {
    font-size: 80px;
    line-height: 106.41px;
  }

  .contactContentViewStyles {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 440px) {
  .linkedInTextStyles {
    font-size: 64px;
    line-height: 85.13px;
  }
}

@media screen and (max-width: 360px) {
  .footerContainerStyles {
    padding: 24px 16px;
  }
  .linkedInTextStyles {
    font-size: 48px;
    line-height: 63.84px;
  }

  .bottomAddressBlockStyles {
    justify-content: center;
    align-items: center;
  }

  .bottomAddressSubBlockStyles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px;
  }

  .bottomCopyRightTextStyles {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
}

/* Media Query End */
