.menuDataContainerStyles {
    display: flex;
    flex-direction: row;
    gap: 48px;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
}

.activeMenuItemTextStyles,
.menuItemTextStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.28px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0A66C2;
    cursor: pointer;
}

.menuItemTextStyles {
    color: #212121;
}

.hamberMenuBlockStyles {
    display: none;
}

/* Media Query Start */
@media screen and (max-width:1440px) {}

@media screen and (max-width:1287px) {}

@media screen and (max-width:1039px) {}

@media screen and (max-width:740px) {}

@media screen and (max-width:560px) {
    .menuDataContainerStyles {
        display: none;
    }

    .hamberMenuBlockStyles {
        display: flex;
        /* padding: 14px 24px; */
        justify-content: flex-end;
    }

    .hamberMenuImageStyles {
        margin: 14px 24px;
    }

    .removeHambergerMenuStyles {
        display: none;
    }

    .showHambergerMenuStyles {
        position: fixed;
        background-color: #fff;
    }

    .hamberMenuImageStyles,
    .closeImageImageStyles {
        width: 24px;
        height: 24px;
    }

    .hambergerMenuItemViewStyles {
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
    }

    .closeImgBlockStyles {
        display: flex;
        align-self: flex-end;
        padding: 14px 16px;
    }

    .hambergerMenuDataBlockStyles {
        display: flex;
        flex-direction: column;
        gap: 34px;
        padding: 24px 16px;
    }

    .activeHamberMenuItemTextStyles {
        font-family: "Segoe UI", sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #18212A;
        cursor: pointer;
    }

    .hambergerMenuItemTextStyles {
        font-family: "Segoe UI", sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #18212A80;
        cursor: pointer;
    }
}

@media screen and (max-width:440px) {}

@media screen and (max-width:360px) {
    .showHambergerMenuStyles {
        height: 100%;
    }
}

/* Media Query End */