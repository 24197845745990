.howItWorksContainerStyles {
  max-width: 1640px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.bannerContainerStyles {
  display: flex;
  flex-direction: column;
}

.topInfoContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
}

.linkedinImgStyles {
  width: 196px;
  height: 48px;
}

.topInfoSubContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.topHeadingStyles {
  max-width: 1224px;
  width: 100%;
  font-family: 'Segoe UI', sans-serif;
  font-size: 74px;
  font-weight: 600;
  line-height: 84px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #212121;
}

.topDescStyles {
  max-width: 688px;
  width: 100%;
  font-family: 'Segoe UI', sans-serif;
  font-size: 18px;
  font-weight: 350;
  line-height: 22px;
  letter-spacing: -0.03em;
  text-align: center;
}

/* banner section start */
.bannerImageContainerStyles {
  display: flex;
  align-self: center;
  padding: 0px 24px 88px 24px;
}

.bannerImageStyles {
  max-width: 1592px;
  width: 100%;
  height: 565px;
}

.bannerImageContainStyles {
  object-fit: cover;
  border-radius: 64px;
}

.btnContainerStyles {
  padding: 16px;
}

.bannerInfoTextStyles {
  max-width: 856px;
  width: 100%;
  margin-inline: auto;
  text-align: center;
  padding: 48px 0px 96px 0px;
  font-family: 'Segoe UI', sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 42.56px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #000000e6;
}

/* banner section end */

/* how it works sectio start */
.howItWorksBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0px 24px 96px 24px;
}

.howItWorksHeadingStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 96px;
  font-weight: 350;
  line-height: 127.69px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #000000e6;
}

.middleHowItWorkCardStyles {
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
  justify-content: flex-end;
}

.middleHowItWorkCardStyles> :last-child {
  margin-top: 32px;
}

.howItWorkBtnStyles {
  display: flex;
  width: fit-content;
  align-self: flex-end;
  justify-content: flex-end;
}

/* how it works sectio end */

/* whyChooseBlockStyles start */
.whyChooseBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0px 24px 96px 24px;
}

.whyChooseSubBlockStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.whyChooseViewTextStyles {
  max-width: 520px;
  width: 100%;
  font-family: 'Segoe UI', sans-serif;
  font-size: 96px;
  font-weight: 350;
  line-height: 127.69px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #000000e6;
}

.whyChooseCardViewStyles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.activeWhyChooseCardStyes {
  background-color: #0a2037;
}

.activeWhyChooseTitleStyles,
.activeWhyChooseSubTitleStyles,
.activeWhyChooseDescStyles {
  color: #fff;
}

.hideWhyChooseCardStyles {
  visibility: hidden;
}

.whyChooseBtnStyles {
  width: fit-content;
  background-color: transparent;
  border: 1px solid #0a2037;
  padding: 16px;
}

.whyChooseBtnTitleStyles {
  color: #0a2037;
}

/* whyChooseBlockStyles end */

/* servicesBlockStyles start */
.servicesBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0px 24px 96px 24px;
}

.servicesHeadingViewStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 96px;
  font-weight: 350;
  line-height: 127.69px;
  letter-spacing: -0.03em;
  text-align: right;
}

.bottomServiceBlockStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
}

.progressBarMobileStyles {
  display: none;
}

.progreeBarStyles {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.progressSubViewStyles {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.stepTitleTextStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.94px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #212121;
  cursor: pointer;
}

.activeStepTitleStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.94px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #0a66c2;
}

.progressBarImgStyles {
  width: 4px;
  height: 114px;
}

.servicePostInfoStyles {
  max-width: 696px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.servicePostHeadingStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 37.24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #000000e6;
}

.servicePostDescStyles {
  font-family: 'Segoe UI', sans-serif;
  font-size: 40px;
  font-weight: 350;
  line-height: 53.2px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #000000e5;
}

.serviceImgSectionStyles {
  max-width: 696px;
  height: 595px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  width: 100%;
}

.serviceImgInsideStyles {
  width: 596px;
  height: 595px;
}

.serviceImgStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* servicesBlockStyles end */

/* renderBookACallModalSection start */
.bookACallModalStyles {
  width: 400px;
}

.inputContainerStyles {
  display: flex;
  flex-direction: column;
}

.inputSubContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.cancelAndSubmitBtnStyles {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px;
  justify-content: flex-end;
  border-top: 1px solid #dedede;
}

.cancelBtnStyles {
  border-radius: 16px;
  padding: 12px;
  background-color: transparent;
}

.cancelBtnTitleStyles,
.submitBtnTitleStyles {
  font-size: 16px;
  color: #212121;
}

.submitBtnStyles {
  border-radius: 16px;
  padding: 12px;
}

.submitBtnTitleStyles {
  color: #fff;
}


/* renderBookACallModalSection end */

/* Media Query Start */
@media screen and (max-width: 1440px) {
  .topInfoContainerStyles {
    padding-top: 54px;
  }

  .linkedinImgStyles {
    width: 97.57px;
    height: 24px;
  }

  .topHeadingStyles {
    max-width: 872px;
    font-size: 64px;
    line-height: 74px;
  }

  .btnContainerStyles {
    width: fit-content;
    padding: 12px 16px;
  }

  .bookCallBtnTitleStyles {
    font-size: 18px;
    line-height: 20px;
  }

  .bannerImageContainerStyles {
    padding: 0px 24px 64px 24px;
  }

  .bannerImageStyles {
    width: 100%;
  }

  .bannerInfoTextStyles {
    max-width: 612px;
    font-size: 22px;
    line-height: 30px;
    padding: 48px 0px 64px 0px;
  }

  .howItWorksBlockStyles {
    padding: 0px 24px 64px 24px;
  }

  .howItWorksHeadingStyles {
    font-size: 80px;
    line-height: 107px;
  }

  .howItWorksTextStyles {
    font-size: 40px;
    line-height: 54px;
  }

  .howItWorksDescStyles {
    font-size: 18px;
    line-height: 22px;
  }

  .howItWorkBtnStyles {
    padding: 12px 16px;
  }

  .whyChooseBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 0px 24px 64px 24px;
  }

  .whyChooseViewTextStyles {
    max-width: 402px;
    font-size: 80px;
    line-height: 107px;
  }

  .whyChooseTitleStyles {
    height: 169px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    height: 169px;
  }

  .whyChooseSubTitleStyles,
  .activeWhyChooseSubTitleStyles {
    font-size: 32px;
    line-height: 43px;
  }

  .whyChooseBtnStyles {
    padding: 12px 16px;
  }

  .serviceImgInsideStyles {
    max-width: 100%;
    width: 520px;
  }

  .serviceImgStyles {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
  }
}

@media screen and (max-width: 1287px) {
  .topInfoContainerStyles {
    padding-top: 48px;
  }

  .topHeadingStyles {
    max-width: 724px;
    font-size: 48px;
    line-height: 58px;
  }

  .topDescStyles {
    max-width: 605px;
    font-size: 16px;
    line-height: 20px;
  }

  .bannerImageContainerStyles {
    padding: 0px 24px 48px 24px;
  }

  .bannerInfoTextStyles {
    max-width: 612px;
    font-size: 20px;
    line-height: 28px;
    padding: 48px 0px 48px 0px;
  }

  .howItWorksBlockStyles {
    padding: 0px 24px 48px 24px;
  }

  .howItWorksHeadingStyles {
    font-size: 64px;
    line-height: 86px;
  }

  .howItWorksTextStyles {
    max-width: 230px;
    font-size: 36px;
    line-height: 48px;
  }

  .howItWorksDescStyles {
    max-width: 230px;
    font-size: 16px;
    line-height: 22px;
  }

  .howItWorkBtnStyles {
    padding: 12px 16px;
  }

  .whyChooseBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 0px 24px 48px 24px;
  }

  .whyChooseViewTextStyles {
    max-width: 353px;
    font-size: 64px;
    line-height: 86px;
  }

  .whyChooseTitleStyles {
    font-size: 80px;
    line-height: 107px;
    height: 172px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    font-size: 80px;
    line-height: 107px;
    height: 172px;
  }

  .whyChooseDescStyles,
  .activeWhyChooseDescStyles {
    font-size: 16px;
    line-height: 22px;
  }

  .whyChooseBtnTitleStyles {
    font-size: 18px;
    line-height: 18px;
  }
}

@media screen and (max-width: 1040px) {
  .bannerImageStyles {
    height: 460px;
  }

  .topHeadingStyles {
    max-width: 544px;
    font-size: 40px;
    line-height: 48px;
  }

  .topDescStyles {
    max-width: 605px;
    font-size: 16px;
    line-height: 20px;
  }

  .bannerInfoTextStyles {
    max-width: 538px;
  }

  .howItWorksHeadingStyles {
    font-size: 60px;
    line-height: 80px;
  }

  .whyChooseViewTextStyles {
    max-width: 284px;
    font-size: 60px;
    line-height: 80px;
  }

  .whyChooseTitleStyles {
    height: 183px;
    white-space: nowrap;
  }

  .whyChooseSubTitleStyles {
    white-space: nowrap;
    font-size: 24px;
    line-height: 32px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    height: 183px;
    white-space: nowrap;
  }

  .whyChooseCardStyles {
    max-width: 100%;
  }

  .whyChooseSubTitleStyles,
  .activeWhyChooseSubTitleStyles {
    white-space: nowrap;
    font-size: 24px;
    line-height: 32px;
  }

  .whyChooseDescStyles,
  .activeWhyChooseDescStyles {
    max-width: 272px;
    font-size: 16px;
    line-height: 22px;
  }

  .whyChooseCardStyles,
  .activeWhyChooseCardStyes {
    max-width: 100%;
  }

  .hideWhyChooseCardStyles {
    max-width: 57px;
  }
}

@media screen and (max-width: 740px) {
  .topInfoSubContainerStyles {
    gap: 16px;
  }

  .topHeadingStyles {
    max-width: 492px;
    font-size: 36px;
    line-height: 48px;
  }

  .bannerInfoTextStyles {
    max-width: 100%;
    font-size: 18px;
    line-height: 24px;
    padding: 0px 24px;
  }

  .middleHowItWorkCardStyles {
    grid-template-columns: repeat(2, 1fr);
    justify-content: flex-start;
  }

  .howItWorksHeadingStyles {
    font-size: 54px;
    line-height: 72px;
  }

  .howItWorksTextStyles {
    max-width: 216px;
    font-size: 32px;
    line-height: 42px;
  }

  .howItWorkBtnStyles {
    padding: 12px 16px;
  }

  .whyChooseSubBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .whyChooseViewTextStyles {
    font-size: 54px;
    line-height: 64px;
  }

  .whyChooseCardStyles {
    padding: 16px;
  }

  .whyChooseCardStyles,
  .activeWhyChooseCardStyes {
    padding: 16px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    height: 178px;
  }

  .whyChooseSubTitleStyles,
  .activeWhyChooseSubTitleStyles {
    font-size: 24px;
    line-height: 32px;
  }

  .bottomServiceBlockStyles {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .progreeBarStyles {
    display: flex;
    flex-direction: row;
    gap: 16px;
    order: 3;
    display: none;
  }

  .progressBarMobileStyles {
    display: block;
    order: 3;
  }

  .progressBarMobileInsideStyles {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .progressSubMobileViewStyles {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .progreeBarMobileImgStyles {
    width: 81px;
    height: 2px;
  }

  .progressBarHorizontalImgStyles {
    max-width: 81px;
    width: 100%;
    height: 2px;
    margin-top: -26px;
  }

  .progressMobileStyles {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .servicePostInfoStyles {
    max-width: 100%;
    gap: 8px;
    order: 1;
  }

  .serviceImgSectionStyles {
    max-width: 100%;
    order: 2;
  }

  .serviceImgInsideStyles {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .topHeadingStyles {
    max-width: 392px;
    font-size: 24px;
    line-height: 34px;
  }

  .topDescStyles {
    max-width: 392px;
    font-size: 14px;
    line-height: 20px;
  }

  .btnContainerStyles {
    padding: 12px;
  }

  .bookCallBtnTitleStyles {
    font-size: 16px;
    line-height: 24px;
  }

  .bannerInfoTextStyles {
    font-size: 16px;
    line-height: 22px;
  }

  .middleHowItWorkCardStyles {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .howItWorksHeadingStyles {
    font-size: 48px;
    line-height: 63px;
  }

  .howItWorksTextStyles {
    max-width: 100%;
    font-size: 32px;
    line-height: 42px;
    white-space: nowrap;
  }

  .howItWorksDescStyles {
    max-width: 392px;
    font-size: 18px;
    line-height: 24px;
  }

  .middleHowItWorkCardStyles> :last-child {
    margin-top: 0px;
  }

  .howItWorkBtnStyles {
    padding: 12px 16px;
  }

  .whyChooseViewTextStyles {
    white-space: nowrap;
    font-size: 48px;
    line-height: 64px;
  }

  .whyChooseCardViewStyles {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .hideWhyChooseCardStyles {
    display: none;
  }

  .whyChooseTitleStyles {
    line-height: 156px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    line-height: 156px;
  }

  .whyChooseDescStyles {
    max-width: 360px;
    font-size: 18px;
    line-height: 24px;
  }

  .servicesHeadingViewStyles {
    font-size: 48px;
    text-align: left;
  }

  .whyChooseDescStyles,
  .activeWhyChooseDescStyles {
    max-width: 360px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 440px) {
  .bannerImageStyles {
    height: 393px;
  }

  .howItWorksContainerStyles {
    gap: 24px;
  }

  .topInfoContainerStyles {
    gap: 24px;
    padding-top: 24px;
  }

  .topHeadingStyles {
    max-width: 392px;
    font-size: 24px;
    line-height: 34px;
    padding: 0px 24px;
  }

  .topDescStyles {
    padding: 0px 24px;
  }

  .bannerInfoTextStyles {
    font-size: 16px;
    line-height: 22px;
    padding: 24px;
  }

  .bannerImageContainerStyles {
    padding: 0px 24px 24px 24px;
  }

  .howItWorksBlockStyles {
    gap: 24px;
    padding: 0px 24px 24px 24px;
  }

  .middleHowItWorkCardStyles {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .howItWorksHeadingStyles {
    font-size: 48px;
    line-height: 64px;
    text-align: start;
    color: #000000e6;
    white-space: nowrap;
  }

  .howItWorkBtnStyles {
    padding: 12px 16px;
    align-self: center;
  }

  .whyChooseBlockStyles {
    gap: 24px;
    padding: 0px 24px 24px 24px;
  }

  .whyChooseViewTextStyles {
    text-align: center;
  }

  .whyChooseSubBlockStyles {
    gap: 24px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    font-size: 64px;
    line-height: 85px;
    height: 132px;
  }

  .whyChooseBtnStyles {
    align-self: center;
  }

  .servicesBlockStyles {
    padding: 0px 24px 24px;
    gap: 24px;
  }

  .bottomServiceBlockStyles {
    gap: 24px;
  }

  .servicePostHeadingStyles {
    font-size: 18px;
    line-height: 23.94px;
  }

  .servicePostDescStyles {
    font-size: 20px;
    line-height: 26.6px;
  }

  .bookACallModalOverLayStyles {
    padding: 16px;
  }
}

@media screen and (max-width: 360px) {
  .howItWorksTextStyles {
    font-size: 24px;
    line-height: 32px;
  }

  .whyChooseBlockStyles {
    padding: 0px 16px 24px 16px;
  }

  .whyChooseViewTextStyles {
    font-size: 40px;
    line-height: 64px;
    text-align: center;
  }

  .whyChooseSubBlockStyles {
    gap: 24px;
  }

  .whyChooseTitleStyles,
  .activeWhyChooseTitleStyles {
    font-size: 64px;
    line-height: 85px;
    height: 132px;
  }
}

/* Media Query End */