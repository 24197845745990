.inputContainerStyles {
    max-width: 340px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px;
}

.labelTextStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 42.56px;
    letter-spacing: -0.03em;
    text-align: start;
    color: #000000E6;
}

.inputSubContainerStyles {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 40px;
    justify-content: space-between;
    border: 1px solid #0000001A;
    border-radius: 4px;
    padding: 10px;
    background-color: #F0F0F0;
    align-items: center;
}

.inputStyles {
    width: 100%;
    font-family: "Segoe UI", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: left;
    border: none;
    outline: none;
    background-color: #F0F0F0;
}

.inputStyles:focus {
    border: none;
    outline: none;
    background-color: none;
}

.inputStyles::placeholder {
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000CC;
}

.errorMsgStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: left;
    color: red;
}