.cardContainerStyles {
    max-width: 520px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: space-between;
    border-radius: 24px;
    padding: 24px;
    background-color: #F0F0F0;
    cursor: pointer;
}

.activeCardContainerStyles {
    max-width: 520px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: space-between;
    border-radius: 24px;
    padding: 24px;
    background-color: #0A2037;
}

.cardContainerStyles:hover {
    background-color: #0A2037;
}

.cardContainerStyles:hover .titleTextStyles,
.cardContainerStyles:hover .subTitleTextStyles,
.cardContainerStyles:hover .descriptionTextStyles {
    color: white;
}


.titleTextStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 100px;
    font-weight: 350;
    line-height: 133.01px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0A2037CC;
    height: 186px;
}

.cardSubContentViewStyles {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.subTitleTextStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 47.88px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000E6;
}

.descriptionTextStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000E6;
}