.textAreaContainerStyles {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.textareaStyles {
    width: 100%;
    font-family: "Segoe UI", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.03em;
    text-align: left;
    border: none;
    outline: none;
    background-color: #F0F0F0;
    border-radius: 4px;
    max-width: 340px;
    width: 100%;
    resize: none;
    padding: 10px;
}

.labelTextStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 42.56px;
    letter-spacing: -0.03em;
    text-align: start;
    color: #000000E6;
}