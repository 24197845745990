.btnContainerStyles {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 16px;
    border-radius: 48px;
    align-items: center;
    border: 1px solid #0A2037;
    background-color: #0A2037;
    cursor: pointer;
}

.titleStyles {
    font-family: "Segoe UI", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #FFFFFFE6;
}

.btnImageContainerStyles {
    width: 34px;
    height: 34px;
    border-radius: 24px;
}

.btnImageStyles {
    width: 100%;
    height: 100%;
    object-fit: contain;
}